var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-4"
  }, [_c('i', {
    staticClass: "fa fa-backward"
  }), _c('router-link', {
    attrs: {
      "to": {
        name: _vm.NewspaperPath.name
      }
    }
  }, [_vm._v(" Newspapers ")])], 1)], 1), _c('b-form', {
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('b-card', {
    attrs: {
      "header": _vm.item.name + ' #' + _vm.item.id,
      "header-bg-variant": "primary",
      "header-tag": "h5",
      "header-class": "font-weight-bold"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-display",
      "label": "Display Name (Item’s Title)",
      "label-for": "display"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "display",
      "type": "text",
      "placeholder": "Enter Display Name",
      "disabled": ""
    },
    model: {
      value: _vm.form.display_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "display_name", $$v);
      },
      expression: "form.display_name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Item Status"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "currency",
      "options": _vm.itemStatusOptions,
      "required": ""
    },
    model: {
      value: _vm.form.item_status,
      callback: function ($$v) {
        _vm.$set(_vm.form, "item_status", $$v);
      },
      expression: "form.item_status"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-display",
      "label": "Edition Code"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "edition_code",
      "type": "text",
      "placeholder": "Enter Edition Code"
    },
    model: {
      value: _vm.form.edition_code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "edition_code", $$v);
      },
      expression: "form.edition_code"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('DatetimeField', {
    attrs: {
      "label": 'Release Date (GMT ' + _vm.timezoneString() + ')',
      "maxDate": true
    },
    model: {
      value: _vm.form.release_date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "release_date", $$v);
      },
      expression: "form.release_date"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('DatetimeField', {
    attrs: {
      "label": 'Release Schedule (GMT ' + _vm.timezoneString() + ')',
      "maxDate": true
    },
    model: {
      value: _vm.form.release_schedule,
      callback: function ($$v) {
        _vm.$set(_vm.form, "release_schedule", $$v);
      },
      expression: "form.release_schedule"
    }
  })], 1), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-save"
  }), _vm._v(" Save ")])], 1)], 1)], 1), _c('b-card', {
    attrs: {
      "header": "Upload Asset",
      "header-bg-variant": "primary",
      "header-tag": "h5",
      "header-class": "font-weight-bold"
    }
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', [_c('b-form-file', {
    attrs: {
      "placeholder": "Choose a file or drop it here...",
      "drop-placeholder": "Drop file here...",
      "accept": ".pdf, .epub"
    },
    model: {
      value: _vm.form.file_content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "file_content", $$v);
      },
      expression: "form.file_content"
    }
  })], 1), _c('b-col', [_vm.form.file_content ? _c('b-button', {
    attrs: {
      "pill": "",
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.onUpload
    }
  }, [_c('i', {
    staticClass: "fa fa-upload"
  }), _vm._v(" Upload")]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "lg": "1"
    }
  })], 1), _c('div', [_vm._v("Max Size 150Mb")]), _c('b-row', [_c('b-col', [_vm._v(" File Size: " + _vm._s(_vm._f("convertSize")(_vm.form.file_content ? _vm.form.file_content.size : 0)) + " MB ")]), _c('b-col', [_vm._v(" Current File Size: ( " + _vm._s(_vm._f("convertSize")(_vm.form.current_size ? _vm.form.current_size : 0)) + " MB ) ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
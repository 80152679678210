<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <b-row>
      <b-col class="mt-4">
        <i class="fa fa-backward"></i>
        <router-link :to="{ name: NewspaperPath.name }">
          Newspapers
        </router-link>
      </b-col>
    </b-row>
    <b-form @submit="onSubmit">
      <b-card :header="item.name + ' #' + item.id" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
        <b-row>
          <b-col lg="4" md="6">
            <b-form-group id="input-display" label="Display Name (Item’s Title)" label-for="display">
              <b-form-input id="display" v-model="form.display_name" type="text" placeholder="Enter Display Name" disabled>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6">
            <b-form-group label="Item Status">
              <b-form-select id="currency" v-model="form.item_status" :options="itemStatusOptions" required>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6">
            <b-form-group id="input-display" label="Edition Code">
              <b-form-input id="edition_code" v-model="form.edition_code" type="text" placeholder="Enter Edition Code">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4" md ="6">
            <DatetimeField
              :label="'Release Date (GMT ' + timezoneString() + ')'"
              v-model="form.release_date"
              :maxDate=true
            >
            </DatetimeField>
          </b-col>
          <b-col lg="4" md="6"> 
            <DatetimeField
              :label="'Release Schedule (GMT ' + timezoneString() + ')'"
              v-model="form.release_schedule"
              :maxDate=true
            >
            </DatetimeField>
          </b-col>
          <b-col cols="12" class="text-right">
            <b-button class="mr-3" type="submit" variant="primary">
              <i class="fa fa-save"></i> Save
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card header="Upload Asset" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
        <b-row align-v="center">
          <b-col>
            <b-form-file
              v-model="form.file_content"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              accept=".pdf, .epub"
            >
            </b-form-file>
          </b-col>
          <b-col>
            <b-button
              pill
              size="sm"
              variant="primary"
              @click="onUpload"
              v-if="form.file_content"
            ><i class="fa fa-upload"></i> Upload</b-button>
          </b-col>
          <b-col lg="1"></b-col>
        </b-row>
        <div>Max Size 150Mb</div> 
        <b-row>
          <b-col>
            File Size: {{ form.file_content ? form.file_content.size : 0 | convertSize }} MB
          </b-col>
          <b-col>
            Current File Size: (
            {{ form.current_size ? form.current_size : 0 | convertSize }} MB 
            )
          </b-col>
        </b-row>
      </b-card>
    </b-form>
  </b-overlay>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import { NewspaperPath } from "../../../router/content";
import constant from "../../../store/constant";
import DatetimeField from "../../../components/form/Datetime.vue";

const ITEM_STATUS = Object.entries(constant.ITEM_STATUS).map(item => { return {text: item[0], value: item[1]} })

export default {
  name: "editNewspaper",
  components: {
    DatetimeField, 
  },
  data() {
    return {
      NewspaperPath,
      form: {
        brand: null,
        file_content: null,
        display_name: null,
        item_status: null,
        release_date:null,
        release_schedule: null
      },
      itemStatusOptions: ITEM_STATUS,
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.newspaper.isError,
      isLoading: (state) => state.newspaper.isLoading,
      errorMessage: (state) => state.newspaper.errorMessage,
      successMessage: (state) => state.newspaper.successMessage,
      item: (state) => state.newspaper.item,
      refresh: (state) => state.newspaper.refresh,
    }),
  },
  watch: {
    errorMessage: function () {
      if (!this.errorMessage) return;
      this.messageAlert('error', this.errorMessage, 5000);
    },
    successMessage: function () {
      if (!this.successMessage) return;
      this.messageAlert('success', this.successMessage);
    },
    item: function () {
      if (!Object.keys(this.item).length) return;
      this.setEditNewspaper();
    },
    refresh: function () {
      if (!this.refresh) return;
      this.fetchNewsPaperById({ id: this.item_id });
    },
  },
  created() {
    let id = this.$route.params.id;
    this.item_id = id;
    this.fetchNewsPaperById({ id });
  },
  methods: {
    ...mapActions('newspaper', [
      'updateNewspaper',
      'uploadAsset',
      'fetchNewsPaperById',
    ]),
    onSubmit(event) {
      event.preventDefault();
      const payload = {
        item_id: this.item_id,
        release_schedule: this.form.release_schedule,
        release_date:this.form.release_date,
        item_status: this.form.item_status,
        edition_code: this.form.edition_code,
      };
      this.updateNewspaper(payload);
    },

    onUpload() {
      this.isDownload = true;
      const payload = {
        id: this.item_id,
        file_content: this.form.file_content,
      };
      if (!payload.file_content) {
        this.messageAlert('error', 'No File Selected !', 5000);
        return;
      }
      this.uploadAsset(payload);
    },

    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },

    setEditNewspaper() {
      const data = this.item;
      this.form.display_name = data.name;
      this.form.release_schedule = moment(data.release_schedule).toString();
      this.form.release_date = moment(data.release_date).toString();
      this.form.current_size = data.file_size;
      this.form.file_content = null;
      this.form.item_status = data.item_status;
      this.form.edition_code = data.edition_code;
    },
  },
  filters: {
    convertSize(value) {
      const size = parseFloat(value);
      return (size / 1000 ** 2).toFixed(1)
    }
  }
};
</script>

<style lang="css" scoped>
</style>
